#thank-you {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // height: 50vh;
   > * {
     margin:0.5em;
   }
  h1 {
    color: #ea7423;
    margin-top:2em;
  }
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}