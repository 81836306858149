@import url('https://fonts.googleapis.com/css?family=Open+Sans');
// test
// body.localhost,
// body.test {
//   #root {
//     border: 2px solid yellow;
//     height: calc(100vh - 4px);
//   }
// }

.App {
  text-align: center;
}

h1 {
  border-bottom: 2px solid #ccc;
  color: #333;
  display: block;
}

html,
body {
  font-family: 'Open Sans';
}

html,
body,
#root,
.app {
  height: 100vh;
}

input[type=search] {
  padding: 0.5em;
  border: 1px solid #ccc;
  text-align: center;
}

input[type=submit],
input[type=reset],
button {
  cursor: pointer;
}

input[type=reset] {
  color: red;
}

button[disabled],
input[disabled] {
  cursor: default !important;
}

.back {
  display: flex;
  justify-content: center;
  margin: 0.5em 0 0 0;
}

.button {
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 0.25em 0.5em;
  font-size: 1.1em;
  background: linear-gradient(to bottom, #eee, #ddd);
  text-decoration: none;
  cursor: pointer !important;
  &.back {
    span {
      padding-right: 0.5em;
    }
  }
  &[disabled] {
    background: linear-gradient(to bottom, #ccc, #eee);
    border: 1px solid #bbb;
    color: #999;
    cursor: default !important;
    &:hover {
      background: linear-gradient(to bottom, #ccc, #eee) !important;
    }
  }
  &:hover {
    background: linear-gradient(to bottom, #fff, #eee);
  }
  &:active {
    background: linear-gradient(to bottom, #eee, #fff);
  }
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  >* {
    flex-shrink: 0;
  }
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
  }
}