@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
// #eb7a2c
// body.test, body.localhost {
//   header {
//     padding-top:2em;
//     &:before {
//       content: "Test Platform";
//       letter-spacing: 0.1em;
//       font-weight: bold;
//       background: yellow;
//       opacity: 0.75;
//       padding: 0.25em 0.5em;
//       border-radius: 2px;
//       position: absolute;
//       top: 0;
//       left: 50%;
//       width:120px;
//       margin-left:-60px;
//       text-align: center;
//     }
//   }
// }

header {
  border-bottom: 3px solid #7eb0d7;
  display: flex;
  align-items: center; // flex-direction: column;
  justify-content: space-between;
  padding:0.5em;
  > * {
    min-width:325px;
  }
  #logo-wrap {
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius:6px;
    &:hover {
      background: #eee;
    }
    #logo {
      height: 70px;
    }
    >* {
      margin: 0.25em;
      font-family: Source Sans Pro;
      font-size: 1em;
      font-weight: normal;
      h2,
      h3 {
        margin: 0;
      }
      h3 {
        color: #ea7423;
        border: none; // font-size:0.5em;
      }
      h2 {
        // font-size:1.2em;
        color: #88b6d9;
      }
    }
  }
  #logo {
    margin: 0.5em;
  }
  nav,
  #user {
    margin: 0 1.5em;
  }
  nav {
    a {
      margin: 0.5em;
      padding: 0.5em;
      font-weight: 600;
      font-size:1em;
      text-decoration: none;
      color: black;
      // background: #eee;
      border: 1px solid transparent;
      // border-radius:0px;
      // border-bottom:1px solid black;
      display: inline-block;
      text-transform: uppercase;
      &:hover {
        background: #eee;
        // border-radius: 3px;
        // border-bottom-color:transparent;
      }
      &.current {
        border-radius: 3px;
        background: #7eb0d7;
        color: white;
        border: 1px solid #09f;
      }
    }
  }
  #user {
    display: flex;
    justify-content: flex-end;
    color: #666;
    .name {
      font-weight: 600;
    }
    a,
    button {
      font-size: 14px;
      background: #eee;
      border-radius: 3px;
      padding: 0.5em;
      color: #333;
      border: 1px solid #ccc;
      text-decoration: none;
      &:hover {
        background: #ddd;
      }
    }
    button {
      font-size: 12px;
      color: red;
    }
    >* {
      margin: 0.5em;
    }
  }
}