#loading-animation {
  display: inline-block;
  vertical-align: middle;
  .loading-icons {
    display: flex;
    font-weight: normal !important;
    #a,
    #b,
    #c {
      font-size: 2em;
      color: black;
      animation-name: example;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
    #a {
      animation-delay: 0;
    }
    #b {
      animation-delay: 0.25s;
    }
    #c {
      animation-delay: 0.5s;
    }
    @keyframes example {
      0% {
        color: black;
      }
      50% {
        color: #9cf;
      }
      100% {
        color: black;
      }
    }
  }
}