@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:14px;
}


h1 {
  font-weight: 700;
}
h2,h3,h4,h5,h6 {
  font-weight: 600;
}

code {
  white-space: pre;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
}