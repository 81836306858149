#product {
  display: table;
  border-collapse: collapse;
  .product-row {
    display: table-row;
    .property, .value {
      border:1px solid #ccc;
      padding:0.5em;
      display: table-cell;
    }
  }
}