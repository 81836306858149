.orders-wrap {
  min-width: 750px;
  display: flex;
  flex-direction: column;
  h2 {
    text-align: center;
  }
  #search-wrap {
    // width: 150px;
    align-self: flex-end;
    margin-top: -3em;
    position: relative;
    top: -1.25em;
  }
  .status-wrap {
    width: 175px;
    text-align: right;
  }
  .orders {
    display: table;
    border-collapse: collapse;
    >a,
    .header {
      margin: 0.5em;
      display: table-row;
      border: 1px solid #ccc;
      padding: 0.75em;
      text-decoration: none;
      >* {
        display: table-cell;
        padding: 0.75em;
        border: 1px solid #eee;
        text-align: center;
      }
      .sales-order-number {
        font-weight: bold;
      }
      &:hover {
        background: whiteSmoke;
      }
    }
    a {
      &.submitted {
        border: 1px dashed #ccc;
      }
    }
    .header {
      background: #ddd;
      font-weight: bold;
      border-color: transparent;
      &:hover {
        background: #ddd;
      }
      >* {
        align-self: center;
      }
    }
    .new-order-wrap {
      display: flex;
      align-items: center;
      a.new {
        margin: 0.5em;
        font-size: 1em; 
        // display: inline-block; 
      }
    }
  }
  .button {
    align-self: center;
    margin-top: 1em;
  }
}