@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
header {
  border-bottom: 3px solid #7eb0d7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em; }
  header > * {
    min-width: 325px; }
  header #logo-wrap {
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 6px; }
    header #logo-wrap:hover {
      background: #eee; }
    header #logo-wrap #logo {
      height: 70px; }
    header #logo-wrap > * {
      margin: 0.25em;
      font-family: Source Sans Pro;
      font-size: 1em;
      font-weight: normal; }
      header #logo-wrap > * h2,
      header #logo-wrap > * h3 {
        margin: 0; }
      header #logo-wrap > * h3 {
        color: #ea7423;
        border: none; }
      header #logo-wrap > * h2 {
        color: #88b6d9; }
  header #logo {
    margin: 0.5em; }
  header nav,
  header #user {
    margin: 0 1.5em; }
  header nav a {
    margin: 0.5em;
    padding: 0.5em;
    font-weight: 600;
    font-size: 1em;
    text-decoration: none;
    color: black;
    border: 1px solid transparent;
    display: inline-block;
    text-transform: uppercase; }
    header nav a:hover {
      background: #eee; }
    header nav a.current {
      border-radius: 3px;
      background: #7eb0d7;
      color: white;
      border: 1px solid #09f; }
  header #user {
    display: flex;
    justify-content: flex-end;
    color: #666; }
    header #user .name {
      font-weight: 600; }
    header #user a,
    header #user button {
      font-size: 14px;
      background: #eee;
      border-radius: 3px;
      padding: 0.5em;
      color: #333;
      border: 1px solid #ccc;
      text-decoration: none; }
      header #user a:hover,
      header #user button:hover {
        background: #ddd; }
    header #user button {
      font-size: 12px;
      color: red; }
    header #user > * {
      margin: 0.5em; }


.home {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .home > a {
    background: #eee;
    padding: 0.25em 0.5em;
    text-decoration: none;
    border-radius: 3px;
    border: 1px solid #ddd;
    margin: 1em;
    font-size: 1.5em; }

.order-form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  margin: 2em auto;
  padding: 0 2em;
  box-shadow: 1px 1px 3px #ccc;
  border: 1px solid #ccc; }
  .order-form-wrap h1 {
    padding: 0.2em 0.4em;
    display: block;
    text-align: center; }
  .order-form-wrap .sales-order-info-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .order-form-wrap .sales-order-info-wrap > * {
      display: flex; }
      .order-form-wrap .sales-order-info-wrap > * > * {
        margin: 0.25em; }
        .order-form-wrap .sales-order-info-wrap > * > *:first-child {
          color: #888; }
          .order-form-wrap .sales-order-info-wrap > * > *:first-child:after {
            content: ':'; }
        .order-form-wrap .sales-order-info-wrap > * > *:nth-child(2) {
          color: black; }
  .order-form-wrap form.order-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 980px; }
    .order-form-wrap form.order-form input,
    .order-form-wrap form.order-form textarea {
      border: 1px solid #bbb; }
    .order-form-wrap form.order-form > * .loading {
      padding: 1em;
      text-align: center;
      font-style: italic; }
    .order-form-wrap form.order-form > * .field {
      margin: 0.5em;
      display: flex;
      justify-content: space-between;
      transition: color 0.2s linear; }
      .order-form-wrap form.order-form > * .field[data-current-quantity='0'], .order-form-wrap form.order-form > * .field[data-current-quantity='NaN'] {
        color: #777; }
      .order-form-wrap form.order-form > * .field > * {
        margin: 0 0.2em;
        align-self: center;
        text-align: center; }
      .order-form-wrap form.order-form > * .field .item-unit-price-wrap {
        width: 50px; }
      .order-form-wrap form.order-form > * .field .item-category-wrap {
        min-width: 120px;
        text-align: center; }
        .order-form-wrap form.order-form > * .field .item-category-wrap .item-category {
          background: red;
          border-radius: 5px;
          font-size: 0.8em;
          align-self: center;
          padding: 0.5em;
          background: #eee;
          color: #777; }
          .order-form-wrap form.order-form > * .field .item-category-wrap .item-category[data-category=default] {
            background: transparent; }
          .order-form-wrap form.order-form > * .field .item-category-wrap .item-category[data-category=thermostats] {
            background: whiteSmoke;
            color: #777; }
          .order-form-wrap form.order-form > * .field .item-category-wrap .item-category[data-category=led-bulbs] {
            background: whiteSmoke;
            color: #777; }
      .order-form-wrap form.order-form > * .field .item-image-wrap {
        width: 50px;
        margin: 0 0.5em; }
        .order-form-wrap form.order-form > * .field .item-image-wrap img {
          max-width: 100%; }
      .order-form-wrap form.order-form > * .field .item-sku-wrap {
        width: 125px;
        text-align: left; }
      .order-form-wrap form.order-form > * .field .item-price-wrap {
        width: 60px;
        text-align: right; }
      .order-form-wrap form.order-form > * .field .item-description-wrap {
        max-width: 350px; }
      .order-form-wrap form.order-form > * .field .item-quantity-wrap .item-quantity {
        margin: 0 0.25em 0 0.5em;
        padding: 0.2em;
        font-size: 1em;
        border: 1px solid #ccc;
        width: 65px;
        text-align: right;
        color: inherit; }
      .order-form-wrap form.order-form > * .field .item-quantity-wrap button {
        margin: 0.2em; }
        .order-form-wrap form.order-form > * .field .item-quantity-wrap button.remove {
          color: red;
          font-weight: bold; }
      .order-form-wrap form.order-form > * .field .item-line-total-wrap {
        margin-left: 1em;
        min-width: 75px;
        overflow: visible;
        text-align: right; }
    .order-form-wrap form.order-form .shipping-information {
      font-style: italic; }
    .order-form-wrap form.order-form #addresses {
      display: flex;
      flex: 1 1;
      justify-content: space-around;
      padding: 1em 0 1.5em 0; }
      .order-form-wrap form.order-form #addresses h2 {
        margin: 0.25em; }
      .order-form-wrap form.order-form #addresses .address-wrap {
        text-align: center; }
        .order-form-wrap form.order-form #addresses .address-wrap .address-select-wrap {
          width: 300px;
          border: 1px solid #ddd;
          background: #eee; }
        .order-form-wrap form.order-form #addresses .address-wrap select {
          width: 100%;
          padding: 0.5em; }
        .order-form-wrap form.order-form #addresses .address-wrap .address-preview {
          border: 1px solid #ccc;
          background: #ddd;
          text-align: left;
          white-space: pre;
          overflow: auto; }
    .order-form-wrap form.order-form .items-heading {
      margin: 0; }
    .order-form-wrap form.order-form .products {
      margin: 0.5em 0 0 0;
      border: 1px solid #ccc;
      border-bottom: none;
      background: white;
      display: table;
      border-collapse: separate;
      width: 100%; }
      .order-form-wrap form.order-form .products .field {
        display: table-row;
        border: 1px solid #ccc;
        padding: 0.5em;
        margin: -1px; }
        .order-form-wrap form.order-form .products .field > * {
          display: table-cell;
          vertical-align: middle;
          padding: 1em;
          border-bottom: 1px solid #ccc; }
        .order-form-wrap form.order-form .products .field .item-quantity-wrap {
          width: 250px; }
        .order-form-wrap form.order-form .products .field .item-quantity button {
          font-family: monospace;
          cursor: pointer;
          border-radius: 3px;
          background: linear-gradient(to bottom, #eee, #ddd);
          border: 1px solid #bbb;
          align-self: center;
          padding: 0.25em 0.5em; }
          .order-form-wrap form.order-form .products .field .item-quantity button[disabled] {
            border-color: #ddd; }
        .order-form-wrap form.order-form .products .field.item-heading.field {
          font-weight: 600;
          justify-content: flex-start;
          background: #eee; }
          .order-form-wrap form.order-form .products .field.item-heading.field > * {
            text-align: center; }
    .order-form-wrap form.order-form .add-items-subtotal {
      background: white;
      display: flex;
      width: 100%;
      flex-direction: column;
      border: 1px solid #ccc;
      border-top: none;
      margin: 0; }
    .order-form-wrap form.order-form .subtotal {
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .order-form-wrap form.order-form .subtotal > * {
        margin: 0.75em; }
    .order-form-wrap form.order-form .free-shipping-difference {
      text-align: right;
      padding: 1em;
      color: #444; }
    .order-form-wrap form.order-form .submit-fields {
      margin-top: 2em;
      padding: 1em;
      justify-content: space-between;
      display: flex;
      width: 100%; }
      .order-form-wrap form.order-form .submit-fields [type=submit] {
        font-weight: 600; }
    .order-form-wrap form.order-form .info-fields-addresses-wrap {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%; }
      .order-form-wrap form.order-form .info-fields-addresses-wrap h2 {
        border-bottom: 1px solid transparent; }
      .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields {
        max-width: 350px;
        font-weight: 600; }
        .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields h2 {
          text-align: left;
          border-bottom: 1px solid #ccc;
          padding: 0.25em; }
        .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields .field {
          margin: 0.25em;
          justify-content: space-between; }
          .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields .field select {
            width: 230px; }
          .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields .field label {
            text-align: left;
            min-width: 100px;
            border-bottom: 1px dotted #aaa; }
          .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields .field.customer-comments textarea {
            text-align: left;
            width: 350px;
            height: 50px; }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields .field label {
              width: 100px; }
            .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields .field.customer-comments textarea {
              width: auto !important;
              max-width: 350px; } }
        .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields input,
        .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields textarea,
        .order-form-wrap form.order-form .info-fields-addresses-wrap .info-fields select {
          flex-grow: 1;
          text-align: left;
          border: 1px solid #ccc; }
    .order-form-wrap form.order-form .shipping-fields {
      padding: 1em;
      width: 100%;
      display: flex;
      justify-content: space-around; }
      .order-form-wrap form.order-form .shipping-fields > *.link-billing-and-shipping-wrap {
        align-self: center;
        color: #666; }
        .order-form-wrap form.order-form .shipping-fields > *.link-billing-and-shipping-wrap label {
          color: black; }
      .order-form-wrap form.order-form .shipping-fields > * h2,
      .order-form-wrap form.order-form .shipping-fields > * h3 {
        margin: 0.5em;
        text-align: center; }
      .order-form-wrap form.order-form .shipping-fields > * fieldset {
        border: 1px solid #ddd;
        background: whiteSmoke; }
        .order-form-wrap form.order-form .shipping-fields > * fieldset .field {
          margin: 1em;
          justify-content: flex-start; }
          .order-form-wrap form.order-form .shipping-fields > * fieldset .field.required input,
          .order-form-wrap form.order-form .shipping-fields > * fieldset .field.required textarea {
            border-color: #ccc; }
          .order-form-wrap form.order-form .shipping-fields > * fieldset .field.required label {
            color: black;
            font-weight: 600; }
            .order-form-wrap form.order-form .shipping-fields > * fieldset .field.required label:after {
              content: '*';
              color: red; }
          .order-form-wrap form.order-form .shipping-fields > * fieldset .field label {
            font-size: 0.95em;
            text-align: left;
            width: auto;
            min-width: 150px; }
            .order-form-wrap form.order-form .shipping-fields > * fieldset .field label:after {
              content: ':'; }
          .order-form-wrap form.order-form .shipping-fields > * fieldset .field input {
            text-align: left;
            padding: 0.25em;
            border: 1px solid #ddd;
            min-width: 250px; }
          .order-form-wrap form.order-form .shipping-fields > * fieldset .field input[autocomplete=zip-code] {
            min-width: 80px; }
    .order-form-wrap form.order-form .order-summary-wrap {
      align-self: flex-end; }
      .order-form-wrap form.order-form .order-summary-wrap h2 {
        border-bottom: 1px solid #ccc;
        text-align: right; }
      .order-form-wrap form.order-form .order-summary-wrap .summary {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .order-form-wrap form.order-form .order-summary-wrap .summary > * {
          border-top: 1px dotted #ccc;
          color: black;
          text-align: right;
          justify-content: flex-end;
          display: flex;
          align-items: center; }
          .order-form-wrap form.order-form .order-summary-wrap .summary > *:first-child {
            border-top: none; }
          .order-form-wrap form.order-form .order-summary-wrap .summary > *:last-child {
            border-top: 1px solid #aaa; }
          .order-form-wrap form.order-form .order-summary-wrap .summary > * h2,
          .order-form-wrap form.order-form .order-summary-wrap .summary > * h3,
          .order-form-wrap form.order-form .order-summary-wrap .summary > * h4,
          .order-form-wrap form.order-form .order-summary-wrap .summary > * h5 {
            color: #444; }
            .order-form-wrap form.order-form .order-summary-wrap .summary > * h2:after,
            .order-form-wrap form.order-form .order-summary-wrap .summary > * h3:after,
            .order-form-wrap form.order-form .order-summary-wrap .summary > * h4:after,
            .order-form-wrap form.order-form .order-summary-wrap .summary > * h5:after {
              content: ':'; }
          .order-form-wrap form.order-form .order-summary-wrap .summary > * > * {
            margin: 0.7em; }
          .order-form-wrap form.order-form .order-summary-wrap .summary > * .shipping-and-handling-value,
          .order-form-wrap form.order-form .order-summary-wrap .summary > * .tax-value,
          .order-form-wrap form.order-form .order-summary-wrap .summary > * .total-price-value {
            min-width: 50px; }
    .order-form-wrap form.order-form .submit-fields {
      justify-content: space-around !important; }
      .order-form-wrap form.order-form .submit-fields > * {
        margin: 0.5em; }
  .order-form-wrap .points-of-contact {
    padding: 1em;
    font-style: italic; }
    .order-form-wrap .points-of-contact a {
      color: blue; }

.item-picker-modal-wrap .add,
.item-picker-modal-wrap .cancel {
  font-family: inherit;
  font-size: inherit; }

.item-picker-modal-wrap .add {
  display: flex;
  justify-content: center !important; }
  .item-picker-modal-wrap .add .button {
    margin: 1em !important; }

.item-picker-modal-wrap .cancel {
  margin: 1em;
  color: red;
  cursor: pointer; }

.item-picker-modal-wrap .item-picker-modal {
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9); }
  .item-picker-modal-wrap .item-picker-modal [type=search] {
    margin: 0.5em;
    text-align: left; }
  .item-picker-modal-wrap .item-picker-modal > * {
    cursor: auto; }
  .item-picker-modal-wrap .item-picker-modal .item-picker-wrap {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    padding: 0.25em 0;
    box-shadow: 0px 0px 3px #ccc;
    min-width: 60vw;
    display: inline-block;
    text-align: center; }
    .item-picker-modal-wrap .item-picker-modal .item-picker-wrap h2 {
      margin: 0.5em; }
    .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker-items-wrap {
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid #ccc;
      height: 60vh;
      margin: 1em; }
    .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker {
      background: white;
      display: table;
      border-collapse: collapse;
      width: 100%; }
      .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .header {
        display: table-row;
        background: #ddd;
        font-weight: bold; }
        .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .header > div {
          vertical-align: middle;
          padding: 0.25em 1em;
          display: table-cell; }
        .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .header:hover {
          background: #ddd;
          cursor: inherit; }
      .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .quantity {
        width: 50px;
        padding: 0.25em; }
      .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .item {
        display: table-row;
        vertical-align: middle; }
        .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .item > div {
          padding: 0.5em 1em;
          display: table-cell;
          vertical-align: middle;
          border: 1px solid #eee;
          border-bottom-color: #ccc;
          text-align: left;
          height: inherit; }
          .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .item > div.description {
            max-width: 350px; }
        .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .item .image {
          height: 40px; }
          .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .item .image img {
            max-width: 100%;
            max-height: 100%; }
        .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .item:hover {
          background: #eee; }
        .item-picker-modal-wrap .item-picker-modal .item-picker-wrap .item-picker .item:active {
          background: #ddd; }

#loading-animation {
  display: inline-block;
  vertical-align: middle; }
  #loading-animation .loading-icons {
    display: flex;
    font-weight: normal !important; }
    #loading-animation .loading-icons #a,
    #loading-animation .loading-icons #b,
    #loading-animation .loading-icons #c {
      font-size: 2em;
      color: black;
      -webkit-animation-name: example;
              animation-name: example;
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }
    #loading-animation .loading-icons #a {
      -webkit-animation-delay: 0;
              animation-delay: 0; }
    #loading-animation .loading-icons #b {
      -webkit-animation-delay: 0.25s;
              animation-delay: 0.25s; }
    #loading-animation .loading-icons #c {
      -webkit-animation-delay: 0.5s;
              animation-delay: 0.5s; }

@-webkit-keyframes example {
  0% {
    color: black; }
  50% {
    color: #9cf; }
  100% {
    color: black; } }

@keyframes example {
  0% {
    color: black; }
  50% {
    color: #9cf; }
  100% {
    color: black; } }

#order-preview-wrap {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: rgba(200, 200, 200, 0.75); }
  #order-preview-wrap #order-preview {
    cursor: default;
    max-height: 90vh;
    border: 1px solid black;
    background: white;
    box-shadow: 0px 0px 30px #aaa;
    overflow-y: auto;
    overflow-x: visible;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    align-self: center;
    padding: 1em; }
    #order-preview-wrap #order-preview h2 {
      margin: 0 0 0.5em 0;
      padding: 0 0 0.5em 0;
      border-bottom: 1px solid #ccc;
      width: 100%;
      font-weight: 700;
      text-align: center; }
    #order-preview-wrap #order-preview h3 {
      text-align: center;
      margin: 0.5em 0.25em 0.25em 0.25em; }
    #order-preview-wrap #order-preview code {
      font-size: 10px;
      max-width: 80vw; }
    #order-preview-wrap #order-preview .actions {
      border-top: 1px solid #ddd;
      display: flex;
      justify-content: space-around;
      align-items: center;
      min-width: 500px;
      padding: 0.25em; }
      #order-preview-wrap #order-preview .actions button {
        margin: 1em; }
        #order-preview-wrap #order-preview .actions button.cancel {
          color: red; }
        #order-preview-wrap #order-preview .actions button.submit {
          font-weight: 600; }
    #order-preview-wrap #order-preview #details-addresses {
      display: flex;
      justify-content: space-around;
      width: 100%; }
    #order-preview-wrap #order-preview #details {
      display: flex;
      flex-direction: column;
      padding: 0.5em; }
      #order-preview-wrap #order-preview #details .field {
        display: flex;
        min-width: 300px;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0.25em;
        font-size: 14px; }
        #order-preview-wrap #order-preview #details .field > * {
          padding: 0em 0.25em; }
        #order-preview-wrap #order-preview #details .field > *:nth-child(1) {
          align-self: center;
          color: #666;
          width: 175px; }
        #order-preview-wrap #order-preview #details .field > *:nth-child(2) {
          font-weight: 600;
          text-align: left;
          flex-grow: 1;
          color: black; }
        #order-preview-wrap #order-preview #details .field.customer-comments > *:nth-child(2) {
          display: inline-block;
          max-width: 300px;
          max-height: 75px;
          overflow-y: auto; }
    #order-preview-wrap #order-preview #addresses {
      display: flex;
      justify-content: center; }
      #order-preview-wrap #order-preview #addresses > * {
        margin: 0 1em; }
        #order-preview-wrap #order-preview #addresses > * .address-preview {
          background: #eee;
          border: 1px solid #ddd; }
  #order-preview-wrap .items {
    margin: 1em 0;
    width: 100%; }
    #order-preview-wrap .items .category,
    #order-preview-wrap .items .image {
      display: none; }
  #order-preview-wrap .actions {
    padding: 1em; }
  #order-preview-wrap .summary {
    padding: 0;
    justify-content: space-around;
    text-align: center;
    display: flex; }
    #order-preview-wrap .summary .loading-icons {
      height: 1.4em;
      position: relative;
      top: -0.5em; }
    #order-preview-wrap .summary h2,
    #order-preview-wrap .summary h3 {
      border-bottom: 1px solid #ccc;
      min-width: 150px;
      font-size: 16px; }
    #order-preview-wrap .summary > *:last-child .total-price-value {
      font-weight: 700; }
  #order-preview-wrap .subtotal {
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0.5em 0;
    padding: 0.5em; }
    #order-preview-wrap .subtotal > *:first-child {
      padding: 0 1em;
      margin: 0 1em; }
      #order-preview-wrap .subtotal > *:first-child:after {
        content: ': '; }

.quantified-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: -1px;
  border: 1px solid #ddd; }
  .quantified-item .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    padding: 0.75em; }
    .quantified-item .item > * {
      margin: 0 0.25em; }
    .quantified-item .item .sku {
      font-weight: 600;
      width: 130px; }
    .quantified-item .item .label {
      width: 300px; }
    .quantified-item .item .image {
      width: 50px; }
      .quantified-item .item .image img {
        max-width: 100%;
        max-height: 100%; }
  .quantified-item .category {
    width: 120px; }
  .quantified-item .rate {
    width: 80px;
    text-align: right; }
  .quantified-item .quantity {
    flex: 1 1;
    text-align: center; }
  .quantified-item .quantity-x {
    color: #888;
    width: 30px;
    text-align: center; }
  .quantified-item .quantified-price-equals {
    color: #888;
    width: 30px;
    text-align: center; }
  .quantified-item .quantified-price {
    text-align: right;
    width: 80px;
    flex-shrink: 0;
    padding: 0.75em; }
  .quantified-item.header {
    background: #ddd;
    font-weight: 600;
    color: black;
    padding: 0.5em;
    box-shadow: none; }
    .quantified-item.header .quantity .text {
      font-size: 14px; }
    .quantified-item.header .item {
      background: none; }
    .quantified-item.header .quantified-price {
      width: 90px; }
  .quantified-item.footer {
    padding: 0.5em;
    box-shadow: none; }
    .quantified-item.footer:last-child {
      background: #333; }
      .quantified-item.footer:last-child > * {
        color: #ccc; }
    .quantified-item.footer > * {
      background: none; }

.address-preview {
  padding: 0.75em; }
  .address-preview.loading {
    text-align: center; }
  .address-preview .city-state-zip {
    display: flex; }
    .address-preview .city-state-zip > *:before {
      content: ', '; }
    .address-preview .city-state-zip > *:first-child {
      margin-left: 0; }
      .address-preview .city-state-zip > *:first-child:before {
        content: none; }

.address-select-wrap fieldset .field {
  font-size: 12px !important; }
  .address-select-wrap fieldset .field > * {
    margin: 0.1em 0 !important;
    width: auto !important; }

.address-select-wrap fieldset label, .address-select-wrap fieldset input {
  text-align: left !important; }

.products {
  display: table;
  border-collapse: collapse; }
  .products .product-wrap {
    display: table-row; }
    .products .product-wrap > * {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      padding: 0.5em;
      border: 1px solid #ccc; }
    .products .product-wrap:hover {
      background: whiteSmoke; }
    .products .product-wrap.header {
      font-weight: bold; }
      .products .product-wrap.header > * {
        background: #eee; }
    .products .product-wrap .category-wrap {
      margin-right: 0.5em; }
      .products .product-wrap .category-wrap .category {
        display: inline-block;
        background: #eee;
        padding: 0.5em;
        border-radius: 5px;
        text-decoration: none; }
        .products .product-wrap .category-wrap .categorya:hover {
          background: #ddd; }
    .products .product-wrap .image {
      padding: 0.5em 0.25em;
      height: 50px; }
      .products .product-wrap .image img {
        max-width: 100%;
        max-height: 100%; }
    .products .product-wrap .description {
      max-width: 350px; }

#product {
  display: table;
  border-collapse: collapse; }
  #product .product-row {
    display: table-row; }
    #product .product-row .property, #product .product-row .value {
      border: 1px solid #ccc;
      padding: 0.5em;
      display: table-cell; }

.order-wrap {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: center; }
  .order-wrap .order {
    margin: 1em;
    display: flex;
    flex-direction: column;
    border: 2px solid #ccc;
    padding: 0.5em; }
    .order-wrap .order h2 {
      margin: 1em 0;
      border-bottom: 1px solid #ccc; }
    .order-wrap .order a.print {
      border-radius: 3px;
      border: 1px solid #ccc;
      padding: 0.25em 0.5em;
      text-decoration: none;
      margin: 0.5em;
      margin-top: -3em;
      position: relative;
      top: -1em;
      display: inline-block;
      align-self: flex-end;
      font-weight: 600; }
    .order-wrap .order > * {
      margin: 0.25em; }
    .order-wrap .order > *:before {
      content: attr(data-label);
      color: #333;
      font-weight: bold;
      padding-right: 0.5em; }
    .order-wrap .order.loading .items {
      display: flex;
      flex-direction: column; }
      .order-wrap .order.loading .items .loading-items-icon {
        align-self: center; }
    .order-wrap .order .status {
      display: inline-block;
      padding: 0.2em 0.4em; }
    .order-wrap .order .shipping-instructions {
      max-width: 650px; }
    .order-wrap .order .addresses {
      margin-top: 1em;
      display: flex;
      justify-content: center; }
      .order-wrap .order .addresses:before {
        content: none; }
      .order-wrap .order .addresses > *:before {
        content: attr(data-label);
        color: #333;
        font-weight: bold;
        padding-right: 0 !important; }
      .order-wrap .order .addresses .shipping-address .address-preview,
      .order-wrap .order .addresses .billing-address .address-preview {
        margin: 0.5em;
        width: 300px;
        overflow: auto;
        font-weight: normal;
        background: #eee;
        padding: 0.5em; }
    .order-wrap .order .summary {
      margin: 0 0.75em; }
  .order-wrap .contact-about-order {
    font-style: italic;
    margin: 1em; }

.status,
.status-text {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
  padding: 0.25em 0.5em; }
  .status[data-status="Pending Fulfillment"],
  .status-text[data-status="Pending Fulfillment"] {
    background: #9cf;
    color: white; }
  .status[data-status="Cancelled"],
  .status-text[data-status="Cancelled"] {
    color: red; }
  .status[data-status="Pending Billing"],
  .status-text[data-status="Pending Billing"] {
    background: #46c846;
    color: white; }
  .status[data-status="Billed"],
  .status-text[data-status="Billed"] {
    color: green; }

.summary {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .summary > * {
    border-top: 1px dotted #ccc;
    color: black;
    text-align: right;
    justify-content: flex-end;
    display: flex;
    align-items: center; }
    .summary > *:first-child {
      border-top: none; }
    .summary > *:last-child {
      border-top: 1px solid #aaa; }
    .summary > * h2,
    .summary > * h3,
    .summary > * h4,
    .summary > * h5 {
      border: none !important;
      color: #444; }
      .summary > * h2:after,
      .summary > * h3:after,
      .summary > * h4:after,
      .summary > * h5:after {
        content: ':'; }
    .summary > * > * {
      margin: 0.7em; }
    .summary > * .shipping-and-handling-value,
    .summary > * .tax-value,
    .summary > * .total-price-value {
      min-width: 50px; }

#thank-you {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  #thank-you > * {
    margin: 0.5em; }
  #thank-you h1 {
    color: #ea7423;
    margin-top: 2em; }
  #thank-you .loading {
    display: flex;
    align-items: center;
    justify-content: center; }

.orders-wrap {
  min-width: 750px;
  display: flex;
  flex-direction: column; }
  .orders-wrap h2 {
    text-align: center; }
  .orders-wrap #search-wrap {
    align-self: flex-end;
    margin-top: -3em;
    position: relative;
    top: -1.25em; }
  .orders-wrap .status-wrap {
    width: 175px;
    text-align: right; }
  .orders-wrap .orders {
    display: table;
    border-collapse: collapse; }
    .orders-wrap .orders > a,
    .orders-wrap .orders .header {
      margin: 0.5em;
      display: table-row;
      border: 1px solid #ccc;
      padding: 0.75em;
      text-decoration: none; }
      .orders-wrap .orders > a > *,
      .orders-wrap .orders .header > * {
        display: table-cell;
        padding: 0.75em;
        border: 1px solid #eee;
        text-align: center; }
      .orders-wrap .orders > a .sales-order-number,
      .orders-wrap .orders .header .sales-order-number {
        font-weight: bold; }
      .orders-wrap .orders > a:hover,
      .orders-wrap .orders .header:hover {
        background: whiteSmoke; }
    .orders-wrap .orders a.submitted {
      border: 1px dashed #ccc; }
    .orders-wrap .orders .header {
      background: #ddd;
      font-weight: bold;
      border-color: transparent; }
      .orders-wrap .orders .header:hover {
        background: #ddd; }
      .orders-wrap .orders .header > * {
        align-self: center; }
    .orders-wrap .orders .new-order-wrap {
      display: flex;
      align-items: center; }
      .orders-wrap .orders .new-order-wrap a.new {
        margin: 0.5em;
        font-size: 1em; }
  .orders-wrap .button {
    align-self: center;
    margin-top: 1em; }

footer {
  margin-top: 5em;
  background: #7eb0d7;
  display: flex;
  padding: 0.5em;
  align-items: flex-start;
  justify-content: space-around;
  color: #eee;
  width: 100%;
  align-self: flex-end;
  min-height: 100px; }
  footer h3 {
    margin: 0;
    padding: 0.25em 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25); }
  footer p {
    margin: 0.25em; }
  footer > * {
    width: 350px; }

.welcome-message {
  text-align: center; }

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .login form.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .login form.login-form .field {
      margin: 0.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 300px; }
      .login form.login-form .field.rememberme {
        justify-content: flex-start; }
        .login form.login-form .field.rememberme input {
          margin-left: 2em; }
      .login form.login-form .field label {
        font-weight: 600; }
    .login form.login-form input {
      margin: 0.5em;
      padding: 0.25em; }
      .login form.login-form input[type=text], .login form.login-form input[type=email], .login form.login-form input[type=password] {
        width: 200px; }
  .login.error .error {
    text-align: center;
    color: red; }

#profile {
  display: flex;
  flex-direction: column; }
  #profile > div {
    margin: 0.5em;
    font-weight: normal; }
    #profile > div:before {
      content: attr(data-label) ": ";
      font-weight: bold; }
  #profile .address h2 {
    margin: 1em 0; }
  #profile .address:before {
    content: attr(data-label) ": ";
    font-weight: bold;
    font-size: 13px; }
  #profile .address .address-preview {
    background: #eee; }
  #profile .netsuite {
    align-self: center; }
    #profile .netsuite:before {
      content: none; }

.change-password .field {
  padding: 0.5em; }
  .change-password .field label {
    display: inline-block;
    min-width: 180px;
    font-weight: bold; }
    .change-password .field label.required:after {
      content: '*';
      color: red;
      font-size: 10px;
      vertical-align: super; }
  .change-password .field input {
    padding: 0.25em;
    min-width: 200px; }

.change-password .new-password-wrap {
  margin: 1em 0 1em -1em;
  padding: 1em;
  background: #eee;
  display: flex;
  align-items: center; }
  .change-password .new-password-wrap b {
    margin-left: 1.5em; }
  .change-password .new-password-wrap > * {
    margin-right: 1em; }
  .change-password .new-password-wrap .instructions {
    font-style: italic; }
  .change-password .new-password-wrap ul li[data-is-valid=true] {
    color: #00c800; }
  .change-password .new-password-wrap ul li[data-is-valid=false] {
    color: red; }

.change-password .actions {
  display: flex;
  justify-content: center; }

.App {
  text-align: center; }

h1 {
  border-bottom: 2px solid #ccc;
  color: #333;
  display: block; }

html,
body {
  font-family: 'Open Sans'; }

html,
body,
#root,
.app {
  height: 100vh; }

input[type=search] {
  padding: 0.5em;
  border: 1px solid #ccc;
  text-align: center; }

input[type=submit],
input[type=reset],
button {
  cursor: pointer; }

input[type=reset] {
  color: red; }

button[disabled],
input[disabled] {
  cursor: default !important; }

.back {
  display: flex;
  justify-content: center;
  margin: 0.5em 0 0 0; }

.button {
  border-radius: 3px;
  border: 1px solid #aaa;
  padding: 0.25em 0.5em;
  font-size: 1.1em;
  background: linear-gradient(to bottom, #eee, #ddd);
  text-decoration: none;
  cursor: pointer !important; }
  .button.back span {
    padding-right: 0.5em; }
  .button[disabled] {
    background: linear-gradient(to bottom, #ccc, #eee);
    border: 1px solid #bbb;
    color: #999;
    cursor: default !important; }
    .button[disabled]:hover {
      background: linear-gradient(to bottom, #ccc, #eee) !important; }
  .button:hover {
    background: linear-gradient(to bottom, #fff, #eee); }
  .button:active {
    background: linear-gradient(to bottom, #eee, #fff); }

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: auto; }
  .app > * {
    flex-shrink: 0; }
  .app main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:14px;
}


h1 {
  font-weight: 700;
}
h2,h3,h4,h5,h6 {
  font-weight: 600;
}

code {
  white-space: pre;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
}
