.products {
  display: table;
  border-collapse: collapse;
  .product-wrap {
    display: table-row;
    > * {
      display: table-cell;
      text-align:center;
      vertical-align: middle;
      padding:0.5em;
      border:1px solid #ccc;
    }
    &:hover {
      background:whiteSmoke;
    }
    &.header {
      font-weight:bold;
      > * {
        background:#eee;
      }
    }
    .category-wrap {
      margin-right: 0.5em;
      .category {
        display: inline-block;
        background: #eee;
        padding: 0.5em;
        border-radius: 5px;
        text-decoration: none;
        &a:hover {
          background: #ddd;
        }
      }
    }
    .image {
      padding: 0.5em 0.25em;
      height: 50px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .description {
      max-width:350px;
    }
  }
}