footer {
  margin-top: 5em;
  background: #7eb0d7;
  display: flex;
  padding:0.5em;
  align-items: flex-start;
  justify-content: space-around;
  color: #eee;
  width: 100%;
  align-self: flex-end;
  min-height:100px;
  h3 {
    margin: 0;
    padding:0.25em 0;
    border-bottom:1px solid rgba(255,255,255,0.25);
  }
  p {
    margin:0.25em;
  }
  > * {
    width:350px;
  }
}