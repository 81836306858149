#profile {
  display: flex;
  flex-direction: column;
  >div {
    &:before {
      content: attr(data-label) ': ';
      font-weight: bold;
    }
    margin:0.5em;
    font-weight: normal;
  }
  .address {
    h2 {
      margin: 1em 0;
    }
    &:before {
      content: attr(data-label) ': ';
      font-weight: bold;
      font-size: 13px;
    }
    .address-preview {
      background: #eee;
    }
  }
  .netsuite {
    align-self: center;
    &:before {
      content:none;
    }
  }
}