#order-preview-wrap {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: rgba(200, 200, 200, 0.75);
  #order-preview {
    cursor: default; // display: flex;
    max-height: 90vh;
    border: 1px solid black; // justify-content: center;
    background: white;
    box-shadow: 0px 0px 30px #aaa;
    overflow-y: auto;
    overflow-x: visible;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    align-self: center;
    padding: 1em;
    h2 {
      margin: 0 0 0.5em 0;
      padding: 0 0 0.5em 0;
      border-bottom: 1px solid #ccc;
      width: 100%;
      font-weight: 700;
      text-align: center;
    }
    h3 {
      text-align: center;
      margin: 0.5em 0.25em 0.25em 0.25em;
    }
    code {
      // temp
      font-size: 10px;
      max-width: 80vw;
    }
    .actions {
      border-top:1px solid #ddd;
      display: flex;
      justify-content: space-around;
      align-items: center;
      min-width: 500px;
      padding: 0.25em;
      button {
        margin: 1em;
        &.cancel {
          color: red;
        }
        &.submit {
          font-weight: 600;
        }
      }
    }
    #details-addresses {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
    #details {
      display: flex;
      flex-direction: column; // background: #eee;
      // border: 1px solid #ddd;
      padding: 0.5em;
      .field {
        display: flex;
        min-width: 300px;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0.25em;
        font-size: 14px;
        >* {
          padding: 0em 0.25em; // margin: 0 0.25em;
        }
        >*:nth-child(1) {
          align-self: center;
          color: #666;
          width: 175px; // font-weight: 600;
        }
        >*:nth-child(2) {
          // padding: 0.5em;
          font-weight: 600; // border-bottom: 1px solid #bbb;
          text-align: left;
          flex-grow: 1;
          color: black;
        }
        &.customer-comments {
          >*:nth-child(2) {
            display: inline-block;
            max-width: 300px;
            max-height: 75px;
            overflow-y: auto;
          }
        }
      }
    }
    #addresses {
      display: flex;
      justify-content: center;
      >* {
        margin: 0 1em;
        .address-preview {
          background: #eee; // min-height: 200px;
          border: 1px solid #ddd;
        }
      }
    }
  }
  .items {
    margin: 1em 0;
    width: 100%;
    .category,
    .image {
      display: none;
    }
  }
  .actions {
    padding: 1em;
  }
  .summary {
    // margin-top: 2em;
    .loading-icons {
      height: 1.4em;
      position: relative;
      top:-0.5em;
    }
    h2,
    h3 {
      border-bottom: 1px solid #ccc;
      min-width: 150px;
      font-size: 16px;
    }
    padding:0;
    justify-content: space-around;
    text-align: center;
    display: flex;
    >*:last-child .total-price-value {
      font-weight: 700;
    }
  }
  .subtotal {
    background: #eee;
    // border:1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0.5em 0;
    padding: 0.5em;
    >* {
      &:first-child {
        padding: 0 1em;
        margin: 0 1em;
        &:after {
          content: ': ';
        }
      }
    }
  }
}