.item-picker-modal-wrap {
  .add,
  .cancel {
    font-family: inherit;
    font-size: inherit;
  }
  .add {
    display: flex;
    justify-content: center !important;
    .button {
      margin: 1em !important;
    }
  }
  .cancel {
    margin: 1em;
    color: red;
    cursor: pointer;
  }
  .item-picker-modal {
    cursor: pointer;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    [type=search] {
      margin: 0.5em;
      text-align: left;
    }
    >* {
      cursor: auto;
    }
    .item-picker-wrap {
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      padding: 0.25em 0;
      box-shadow: 0px 0px 3px #ccc;
      // height: 80vh;
      min-width:60vw;
      // overflow-y: auto;
      // overflow-x: hidden;
      display: inline-block;
      text-align: center;
      h2 {
        margin: 0.5em;
      }
      .item-picker-items-wrap {
        overflow-y: auto;
        overflow-x: hidden;
        border:1px solid #ccc;
        height:60vh;
        margin: 1em;
      }
      .item-picker {
        background: white;
        display: table; // align-self: stretch;
        border-collapse: collapse; // border: 1px solid #ccc;
        width: 100%;
        .header {
          display: table-row;
          background: #ddd;
          font-weight: bold;
          >div {
            vertical-align: middle;
            padding: 0.25em 1em;
            display: table-cell;
          }
          &:hover {
            background: #ddd;
            cursor: inherit;
          }
        }
        .quantity {
          width:50px;
          padding:0.25em;
        }
        .item {
          display: table-row;
          vertical-align: middle;
          // cursor: pointer;
          >div {
            padding: 0.5em 1em;
            display: table-cell;
            vertical-align: middle;
            border: 1px solid #eee;
            border-bottom-color: #ccc;
            text-align: left;
            height:inherit;
            &.description {
              max-width:350px;
            }
          }
          .image {
            height: 40px;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          &:hover {
            background: #eee;
          }
          &:active {
            background: #ddd;
          }
        }
      }
    }
  }
}