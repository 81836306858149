.quantified-item {
  font-size:14px;
  display: flex;
  align-items: center;
  // margin:0.5em;
  margin:-1px;
  border:1px solid #ddd;
  // box-shadow:0px 0px 3px #ccc;
  // padding:0.25em;
  .item {
    // width:500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#444;
    // background: #eee;
    padding:0.75em;
    >* {
      margin: 0 0.25em;
    }
    .sku {
      font-weight: 600;
      // background:#eee;
      width:130px;
    }
    .label {
      width:300px;
    }
    .image {
      width: 50px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .category {
    width:120px;
  }
  .rate {
    width:80px;
    text-align: right;
    // font-family: consolas;
  }
  .quantity {
    // font-family: consolas;
    // font-weight: 600;
    // font-size: 1.1em;
    flex:1;
    text-align: center;
  }
  .quantity-x {
    color: #888;
    width:30px;
    text-align: center;
  }
  .quantified-price-equals {
    color:#888;
    width:30px;
    text-align: center;
  }
  .quantified-price {
    // font-weight: 600;
    // font-family: consolas;
    text-align: right;
    width:80px;
    flex-shrink:0;
    padding:0.75em;
  }
  &.header {
    background:#ddd;
    font-weight: 600;
    color:black;
    padding:0.5em;
    box-shadow: none;
    .quantity {
      .text {
        font-size:14px;
      }
    }
    .item {
      background:none;
    }
    .quantified-price {
      width:90px;
      // text-align: left;
    }
  }
  &.footer {
    // background:#aaa;
    padding:0.5em;
    box-shadow: none;
    &:last-child {
      background:#333;
      > * {
        color:#ccc;
      }
    }
    > * {
      background:none;
    }
  }
}