.home {
  padding:1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  > a {
    background: #eee;
    padding:0.25em 0.5em;
    text-decoration: none;
    border-radius: 3px;
    border:1px solid #ddd;
    margin:1em;
    font-size:1.5em;
  }
}