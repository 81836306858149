.address-preview {
  padding:0.75em;
  &.loading {
    text-align: center;
  }
  .city-state-zip{
    display: flex;
    > * {
      &:before {
        content:', ';
      }
      &:first-child {
        margin-left:0;
        &:before {
          content:none;
        }
      }
    }
  }
}