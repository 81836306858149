  .status,
  .status-text {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    border-radius: 3px;
    padding: 0.25em 0.5em;
    &[data-status="Pending Fulfillment"] {
      background: #9cf;
      color: white;
    }
    &[data-status="Pending Approval"] {
      // background: #eee;
    }
    &[data-status="Cancelled"] {
      color:red;
      // background: #eee;
    }
    &[data-status="Pending Billing"] {
      background: rgb(70, 200, 70);
      color: white;
    }
    &[data-status="Billed"] {
      color: green;
      // color: white;
    }
  }