$thermostat-background-color: whiteSmoke;
$thermostat-foreground-color: #777;
$led-bulbs-background-color: whiteSmoke;
$led-bulbs-foreground-color:#777;
.order-form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  margin: 2em auto;
  padding: 0 2em;
  box-shadow: 1px 1px 3px #ccc;
  border: 1px solid #ccc;
  h1 {
    padding: 0.2em 0.4em;
    display: block;
    text-align: center;
  }
  .sales-order-info-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    >* {
      display: flex;
      >* {
        margin: 0.25em;
        &:first-child {
          color: #888;
          &:after {
            content: ':';
          }
        }
        &:nth-child(2) {
          color: black; // font-weight: 600;
        }
      }
    }
  }
  form.order-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 980px;
    input,
    textarea {
      border: 1px solid #bbb;
    }
    >* {
      .loading {
        padding: 1em;
        text-align: center;
        font-style: italic;
      }
      .field {
        margin: 0.5em;
        display: flex;
        justify-content: space-between;
        transition: color 0.2s linear;
        &[data-current-quantity='0'],
        &[data-current-quantity='NaN'] {
          color: #777;
        }
        >* {
          margin: 0 0.2em;
          align-self: center;
          text-align: center;
        }
        .item-unit-price-wrap {
          width: 50px;
        }
        .item-category-wrap {
          min-width: 120px;
          text-align: center;
          .item-category {
            background: red;
            border-radius: 5px;
            font-size: 0.8em;
            align-self: center;
            padding: 0.5em;
            background: #eee;
            color: #777; // border:1px solid #ddd;
            &[data-category=default] {
              background: transparent;
            }
            &[data-category=thermostats] {
              background: $thermostat-background-color;
              color: $thermostat-foreground-color;
            }
            &[data-category=led-bulbs] {
              background: $led-bulbs-background-color;
              color: $led-bulbs-foreground-color;
            }
          }
        }
        .item-image-wrap {
          width: 50px;
          margin: 0 0.5em;
          img {
            max-width: 100%;
          }
        } // .item-name-wrap {
        //   display: inline-block;
        //   align-self: center;
        //   width: 400px;
        //   text-align: left;
        // }
        .item-sku-wrap {
          width: 125px;
          text-align: left;
        }
        .item-price-wrap {
          width: 60px;
          text-align: right;
        }
        .item-description-wrap {
          max-width:350px;
        }
        .item-quantity-wrap {
          .item-quantity {
            margin: 0 0.25em 0 0.5em;
            padding: 0.2em;
            font-size: 1em;
            border: 1px solid #ccc;
            width: 65px; // border: none; // border-bottom: 1px solid #555;
            // background: #eee; // font-weight: 600;
            // font-family: monospace;
            text-align: right;
            color: inherit;
          }
          button {
            margin: 0.2em;
            &.remove {
              color: red;
              font-weight: bold;
            }
          }
        }
        .item-line-total-wrap {
          margin-left: 1em;
          min-width: 75px;
          overflow: visible;
          text-align: right; // .item-line-total-value {
          //   border-bottom: 1px dotted #bbb;
          // }
          // .item-line-total-value[data-quantity='0'], .item-line-total-value[data-quantity='NaN'] {
          //   border-bottom: 1px dotted transparent;
          // }
        }
      }
    }
    .shipping-information {
      font-style: italic; // font-size:1.25em;
    }
    #addresses {
      // margin-top: 1em;
      display: flex;
      flex: 1 1;
      justify-content: space-around; // background: #eee;
      padding: 1em 0 1.5em 0;
      h2 {
        margin: 0.25em;
      }
      .address-wrap {
        text-align: center;
        .address-select-wrap {
          width: 300px;
          border: 1px solid #ddd;
          background: #eee; // border-radius: 6px;
          // padding:0.25em;
        }
        select {
          // margin: 0.5em;
          width: 100%;
          padding: 0.5em;
        }
        .address-preview {
          border: 1px solid #ccc;
          background: #ddd;
          text-align: left;
          white-space: pre;
          overflow: auto;
        }
      }
    }
    .items-heading {
      margin: 0;
    }
    .products {
      margin: 0.5em 0 0 0; // box-shadow: 0px 0px 3px #aaa;
      border: 1px solid #ccc;
      border-bottom: none;
      background: white;
      display: table;
      border-collapse: separate;
      width: 100%;
      .field {
        display: table-row;
        border: 1px solid #ccc;
        padding: 0.5em;
        margin: -1px;
        >* {
          display: table-cell;
          vertical-align: middle;
          padding: 1em;
          border-bottom: 1px solid #ccc; // min-width:100px;
        }
        .item-quantity-wrap {
          width: 250px;
        }
        .item-quantity {
          button {
            font-family: monospace;
            cursor: pointer;
            border-radius: 3px;
            background: linear-gradient(to bottom, #eee, #ddd);
            border: 1px solid #bbb;
            align-self: center;
            padding: 0.25em 0.5em;
            &[disabled] {
              border-color: #ddd;
            }
          }
        }
        &.item-heading.field {
          font-weight: 600;
          justify-content: flex-start;
          background: #eee;
          >* {
            text-align: center;
          }
        }
      }
    }
    .add-items-subtotal {
      background: white;
      display: flex;
      width: 100%;
      flex-direction: column;
      border: 1px solid #ccc;
      border-top: none;
      margin: 0;
    }
    .subtotal {
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      >* {
        margin: 0.75em;
      }
    }
    .free-shipping-difference {
      text-align: right;
      padding: 1em;
      color: #444;
    }
    .submit-fields {
      margin-top: 2em; // border:1px dashed #ccc;
      padding: 1em;
      justify-content: space-between;
      display: flex;
      width: 100%;
      [type=submit] {
        font-weight: 600;
      }
    }
    .info-fields-addresses-wrap {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%;
      h2 {
        border-bottom: 1px solid transparent;
      }
      .info-fields {
        max-width: 350px;
        font-weight: 600;
        h2 {
          text-align: left;
          border-bottom: 1px solid #ccc;
          padding: 0.25em;
        }
        .field {
          margin: 0.25em;
          justify-content: space-between; // width: 300px;
          select {
            width: 230px;
          }
          label {
            text-align: left;
            min-width: 100px;
            border-bottom: 1px dotted #aaa;
          }
          &.customer-comments textarea {
            text-align: left;
            width: 350px;
            height: 50px;
          }
          @media all and (-ms-high-contrast: none),
          (-ms-high-contrast: active) {
            label {
              width: 100px;
            }
            &.customer-comments textarea {
              width: auto !important;
              max-width: 350px;
            }
          }
        }
        input,
        textarea,
        select {
          flex-grow: 1;
          text-align: left;
          border: 1px solid #ccc;
        }
        label {
          // text-align:right !important;
        }
      }
    }
    .shipping-fields {
      padding: 1em; // border-top: 1px dashed #ccc;
      width: 100%;
      display: flex;
      justify-content: space-around;
      >* {
        &.link-billing-and-shipping-wrap {
          align-self: center;
          color: #666;
          label {
            color: black;
          }
        }
        h2,
        h3 {
          margin: 0.5em;
          text-align: center;
        }
        fieldset {
          border: 1px solid #ddd;
          background: whiteSmoke;
          .field {
            margin: 1em;
            justify-content: flex-start;
            &.required {
              input,
              textarea {
                border-color: #ccc;
              }
              label {
                color: black;
                font-weight: 600;
                &:after {
                  content: '*';
                  color: red;
                }
              }
            }
            label {
              font-size: 0.95em;
              text-align: left;
              width: auto;
              min-width: 150px; // border-bottom:1px solid #ccc;
              &:after {
                content: ':';
              }
            }
            input {
              text-align: left;
              padding: 0.25em; // background:#eee;
              border: 1px solid #ddd;
              min-width: 250px;
            }
            input[autocomplete=zip-code] {
              min-width: 80px;
            }
          }
        }
      }
    }
    .order-summary-wrap {
      align-self: flex-end;
      h2 {
        border-bottom: 1px solid #ccc;
        text-align: right;
      }
      .summary {
        display: flex; // border: 1px dashed #ccc;
        // background:#ddd;
        flex-direction: column;
        align-items: flex-end;
        >* {
          // min-width: 250px;
          border-top: 1px dotted #ccc;
          &:first-child {
            border-top: none;
          }
          &:last-child {
            border-top: 1px solid #aaa;
          }
          h2,
          h3,
          h4,
          h5 {
            // margin:0;
            color: #444;
            &:after {
              content: ':';
            }
          }
          color:black;
          text-align:right;
          justify-content:flex-end;
          display:flex;
          align-items:center;
          >* {
            margin: 0.7em;
          }
          .shipping-and-handling-value,
          .tax-value,
          .total-price-value {
            min-width: 50px;
          }
        }
      }
    }
    .submit-fields {
      justify-content: space-around !important;
      >* {
        margin: 0.5em;
      }
    }
  }
  .points-of-contact {
    padding:1em;
    font-style: italic;
    a {
      color:blue;
    }
  }
}