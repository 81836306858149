.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  form.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .field {
      margin: 0.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 300px;
      &.rememberme {
        justify-content: flex-start;
        input {
          margin-left:2em;
        }
      }
      label {
        font-weight: 600;
      }
    }
    input {
      margin: 0.5em;
      padding:0.25em;
      &[type=text], &[type=email], &[type=password]{
        width:200px;
      }
    }
  }
  &.error {
    .error {
      text-align: center;
      color: red;
    }
  }
}