.change-password {
  .field {
    padding:0.5em;
    label {
      display: inline-block;
      min-width: 180px;
      font-weight: bold;
      &.required:after {
        content:'*';
        color:red;
        font-size:10px;
        vertical-align: super;
      }
    }
    input {
      padding:0.25em;
      min-width:200px;
    }
  }
  .new-password-wrap {
    margin:1em 0 1em -1em;
    padding:1em;
    background:#eee;
    display: flex;
    align-items:center;
    b {
      margin-left:1.5em;
    }
    > * {
      margin-right:1em;
    }
    .instructions {
      font-style:italic;
    }
    ul {
      li[data-is-valid=true] {
        color: rgb(0, 200, 0);
      }
      li[data-is-valid=false] {
        color: red;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
  }
}