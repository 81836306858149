.summary {
  display: flex;
  // border: 1px dashed #ccc;
  // background:#ddd;
  flex-direction: column;
  align-items: flex-end;
  >* {
    // min-width: 250px;
    border-top: 1px dotted #ccc;
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-top: 1px solid #aaa;
    }
    h2,
    h3,
    h4,
    h5 {
      // margin:0;
      border: none !important;
      color: #444;
      &:after {
        content: ':';
      }
    }
    color:black;
    text-align:right;
    justify-content:flex-end;
    display:flex;
    align-items:center;
    >* {
      margin: 0.7em;
    }
    .shipping-and-handling-value,
    .tax-value,
    .total-price-value {
      min-width: 50px;
    }
  }
}