.order-wrap {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  .order {
    margin: 1em;
    display: flex;
    flex-direction: column;
    border: 2px solid #ccc;
    padding: 0.5em; // font-weight: bold;
    h2 {
      margin: 1em 0;
      border-bottom: 1px solid #ccc;
    }
    a.print {
      border-radius: 3px;
      border: 1px solid #ccc;
      padding: 0.25em 0.5em;
      text-decoration: none;
      margin: 0.5em;
      margin-top: -3em;
      position: relative;
      top: -1em; // font-size:1.25em;
      display: inline-block;
      align-self: flex-end;
      font-weight: 600;
    }
    >* {
      margin: 0.25em;
    }
    >*:before {
      content: attr(data-label);
      color: #333;
      font-weight: bold;
      padding-right: 0.5em;
    }
    &.loading {
      .items {
        display: flex;
        flex-direction: column;
        .loading-items-icon {
          align-self: center;
        }
      }
    }
    .status {
      display: inline-block;
      padding: 0.2em 0.4em;
    }
    .shipping-instructions {
      max-width:650px;
    }
    .addresses {
      margin-top: 1em;
      display: flex;
      justify-content: center;
      &:before {
        content: none;
      }
      >*:before {
        content: attr(data-label);
        color: #333;
        font-weight: bold;
        padding-right: 0 !important; // padding-right: 0.5em;
      }
      .shipping-address,
      .billing-address {
        // margin:0.75em 0.25em;
        .address-preview {
          margin: 0.5em;
          width: 300px;
          overflow: auto;
          font-weight: normal;
          background: #eee;
          padding: 0.5em;
        }
      }
    }
    .summary {
      margin: 0 0.75em;
    }
  }
  .contact-about-order {
    font-style: italic;
    margin:1em;
  }
}